@import '../variables.scss';

/******************************
 Journal Menu
*******************************/

.j-menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    position: relative;
    //height: 100%; //prob pe ff/ie
    > a {
      padding: 3px 6px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      border-width: 1px 0 0 0;
      min-width: 40px;
      transition: color 0.1s ease-out, background-color 0.1s ease-out;
      &:not([href]) {
        cursor: default;
      }
      &::before {
        line-height: 1;
        font-size: 16px;
      }
    }
    &:first-child{
      > a{
        border-width: 0;
      }
    }
  }
  .links-text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mega-menu {
    position: static;
  }
  .j-dropdown .j-menu{
    background-color: white;
  }
}

/******************************
 Top Menu
*******************************/

.top-bar .wrapper {
  justify-content: space-between;
}

.top-menu {
  height: 100%;
  > ul {
    > li {
      + li > a{
        border-width: 0 0 0 1px;
      }
    }
  }
  .dropdown {
    > a{
      &::after {
        transform: rotate(90deg);
        margin-top: 2px;
      }
    }
  }
}

/******************************
 Dropdown
*******************************/

.dropdown-menu {
  font-size: inherit;
}

.j-dropdown {
  margin: 0;
  padding: 0;
  box-shadow: none;
  border-width: 0;
  border-radius: 0;
  min-width: 100%;
  background-color: transparent;
  transform-origin: 50% 0;
  transform: translate3d(0, -10px, 0);
  opacity: 0;
  display: none;
  > ul > li {
    width: 100%;
  }
  //Triangle
  &::before {
    content: "";
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: white;
    pointer-events: none;
    margin-top: -10px;
    display: block;
  }
  .open > & {
    display: block;
  }
  .animating > & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: transform 0.1s ease-out, opacity 0.1s ease-out, visibility 0.1s ease-out;
  }
  > .j-menu > li{
    &:first-child{
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      > a {
        border-width: 0;
        border-radius:inherit;
      }
    }
    &:last-child{
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      > a {
        border-radius:inherit;
      }
    }
  }
}

.multi-level{
  .j-dropdown{
    .j-dropdown{
      top: 0;
      &::before{
        display: none !important;
      }
    }
  }
}
.flyout-menu-item{
  .j-dropdown{
    &::before{
      display: none !important;
    }
  }
}

// Multilevel Indicator
.dropdown {
  > a {
    position: relative;
    > .links-text{
      margin-right: 7px;
    }
    .count-badge {
      margin-right: 5px;
      margin-left: 0;
    }
    &::after {
      content: "\f0da";
      font-family: $icons;
      font-size: 11px;
      position: relative;
      margin-left: auto;
    }
  }
  .j-menu{
    background-color: white;
    > li > a{
      &::before{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.main-menu-item{
  &.dropdown > a > .links-text{
    margin: 0;
  }
}

/******************************
 Menu Label
*******************************/

.menu-label {
  position: absolute;
  font-size: 10px;
  top: 0;
  right: 0;
  .is-sticky &{
    margin-top: 0 !important;
  }
}

/******************************
 Flyout Menu
*******************************/

.flyout-menu {
  display: flex;
  flex-direction: column;
  position: relative;
  > ul > li {
    width: 100%;
  }
  > .menu-item {
    &.mega-menu {
      position: static;
    }
  }
  .menu-label {
    position: relative;
    font-size: 10px;
    margin: 0 5px;
  }
  .j-dropdown {
    top: 0;
    left: 100%;
    padding-top: 0;
    width: auto;
    transform: translate3d(0, 0, 0);
    transition: all 0s ease-out;
    min-width: auto;
  }
  .mega-menu {
    .j-dropdown {
      width: 800px;
      height: 100%;
      .mega-menu-content{
        min-height: 100%;
      }
    }
  }
  .dropdown {
    > a::after {
      margin-left: auto;
    }
  }
}

.flyout > .j-dropdown::before{
  z-index: 1;
}

/******************************
 Accordion Menu
*******************************/

.open-menu {
  display: none;
}

.accordion-menu {
  .open-menu {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 15px;
    transition: color 0.1s ease-out, background-color 0.1s ease-out;
    z-index: 2;
    &[aria-expanded="true"] i::before {
      content: "\f068";
    }
  }
  .menu-item {
    box-shadow: none !important;
    height: auto;
    > a {
      position: relative;
      &::after {
        display: none;
      }
      + div {
        border-width: 1px 0 0 0;
      }
    }
    &.flyout > a{
      + .in {
        border-top-width: 0;
      }
    }
  }
  .j-menu {
    height: auto;
    > li{
      width: 100%;
      > a{
        &::before{
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .j-menu {
      box-shadow: none;
    }
  }
}


