@import 'variables.scss';

/******************************
 Login Page
*******************************/

.login-box {
  display: flex;
  flex-wrap: wrap;
  > div {
    flex: 1 0 300px;
  }
  .login-box{
    .form-group{
      flex-wrap: wrap;
    }
  }
  &::before,
  &::after {
    display: none;
  }
  .well {
    height: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    box-shadow: none;
    background: transparent;
    display: flex;
    flex-direction: column;
    > .btn {
      margin-top: auto;
      align-self: flex-start;
    }
    p:first-of-type {
      display: none;
    }
    .buttons {
      margin-top: auto;
    }
  }
}

.form-group input[type="password"] + a {
  margin-top: 10px;
  position: relative;
  width: 100%;
  flex-grow: 1;
}

.register-page{
  > div {
    width: 100%;
  }
  > p{
    margin-bottom: 20px;
  }
}

/******************************
 My Account
*******************************/

.route-account-account,
.route-affiliate-account {
  #content {
    display: flex;
    flex-direction: column;
    > div {
      margin-bottom: 20px;
    }
  }
}

.account-list {
  display:flex;
  flex-wrap: wrap;
  > li {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    > a {
      display: inline-flex;
      align-items: center;
      line-height: 1.3;
      &::before {
        margin-right: 5px;
      }
    }
  }
}

.route-account-return-add{
  fieldset{
    margin-top: 15px;
  }
}

.route-account-order-info{
  table{
    margin-bottom: 20px;
  }
  h2{
    margin-top: 20px;
  }
}

.route-account-return-info{
  h1 + table,
  .table-responsive{
    margin-bottom: 20px;
  }
}
