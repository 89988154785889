@import '../variables.scss';

.module-catalog{
  .module-body {
    display: flex;
    flex-wrap: wrap;
  }
}

.subitem {
  line-height: 1.2;
  display: flex;
  a{
    font-size: 12px;
    transition: all 0.075s ease-out;
  }
  &::before {
    float: left;
  }
}

.catalog-title{
  display: flex;
}

.item-content{
  height: 100%;
  transition: all 0.1s ease-out;
}

.item-assets {
  display: flex;
}

.subitems{
  flex-grow: 1;
  order:1;
}

