@import '../variables.scss';

//Slider
.module-master_slider {
  max-width: 100%;
  background-size: cover;
  background-position: center;
  height: 100%;
  > img {
    visibility: hidden;
    //display: none !important;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
  &.fullscreen-slider {
    > img {
      visibility: hidden;
      width: 100%;
      height: 100vh;
    }
  }
  .img-2 {
    //display: none;
  }
  iframe {
    border: none;
  }
  video {
    width: 100%;
    height: 100%;
  }
  .ms-slide{
    overflow: hidden;
    .ms-slide-video {
      z-index: 108;
    }
  }
  .ms-view {
    background: transparent;
  }
  .ms-layer-video {
    overflow: hidden;
  }
  .no-show-effect {
    transition-duration: 0s !important;
  }
  .ms-timerbar {
    height: auto !important;
  }
  .ms-time-bar {
    border-width: 0;
  }

  //Hotspot

  .ms-point-center {
    background-clip: padding-box !important;
    cursor: context-menu;
    margin: -16px 0 0 -16px;
    width: 10px;
    height: 10px;
    background: white;
  }
  .ms-tooltip {
    max-width: 200px;
    position: absolute;
    z-index: 20;
    cursor: auto;
    .ms-tooltip-arrow {
      position: absolute;
      border-width: 8px;
      border-color: transparent;
      border-style: solid;
    }
  }
  .ms-tooltip-top {
    .ms-tooltip-arrow {
      top: auto;
      right: auto;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .ms-tooltip-right {
    .ms-tooltip-arrow {
      top: 50%;
      right: auto;
      bottom: auto;
      left: -16px;
      transform: translateY(-50%);
    }
  }
  .ms-tooltip-bottom {
    .ms-tooltip-arrow {
      top: -16px;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .ms-tooltip-left {
    .ms-tooltip-arrow {
      top: 50%;
      right: -16px;
      bottom: auto;
      left: auto;
      transform: translateY(-50%);
    }
  }

  //Arrows
  .ms-nav-next,
  .ms-nav-prev {
    size: 50px;
    background: white;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    position: absolute;
    transition: all 0.1s ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #333;
    }
  }

  .ms-nav-next {
    left: auto;
    right: 0;
  }

  .ms-nav-prev::before {
    transform: scale(-1);
  }
  //Bullets
  .ms-bullets {
    transition: all 0.1s ease-out;
    right: auto;
    width: auto !important;
    .ms-bullets-count {
      right: auto;
      display: flex;
    }
  }
  .ms-bullet {
    transition: all 0.1s ease-in-out;
    border-width: 0;
    border-radius: 0;
    margin: 0 !important;
    background: white;
  }
  .ms-bullet-selected {
    background: #333;
  }
  .ms-thumb-list {
    bottom: 0;
    height: auto !important;
  }
  .ms-thumb-frame {
    transition: all 0.1s ease-out;
    opacity: 1;
    margin-right: 0 !important;
    position: relative;
    &:hover {
      opacity: 1 !important;
    }
  }
  .ms-thumb-frame-selected {
    opacity: 1 !important;
    transform: scale(1);
    z-index: 2;
  }
  > .master-slider {
    display: flex;
    flex-direction: column;
    width: 100%;
    > .ms-container {
      order: 1;
      margin: 0;
    }
  }
  .ms-btn-container{
    width: 100% !important;
  }
}

/******************************
 Revo
*******************************/

//.rev_slider_wrapper {
//  max-width: 100%;
//  background-size: cover;
//  background-position: center;
//  > img {
//    visibility: hidden;
//    max-width: 100%;
//    height: auto;
//  }
//}
//
//.tp-caption-text{
//  > span{
//    font-size: inherit !important;
//  }
//}
//.tp-caption-button{
//  line-height: 1 !important;
//}

/******************************
 Layer
*******************************/

//.layerslider {
//  max-width: 100%;
//  background-size: cover;
//  background-position: center;
//  > img {
//    visibility: hidden;
//    width: 100%;
//    height: auto;
//  }
//  .ls-thumbnail-wrapper {
//    pointer-events: none;
//    .ls-thumbnail {
//      pointer-events: auto;
//      top: 0;
//      transform: translateY(-100%);
//    }
//  }
//}
