@import '../variables.scss';

/******************************
 Links Menu
*******************************/

.links-menu{
  .module-body{
    display: flex;
    width: 100%;
  }
  ul, li{
    list-style: none;
    margin:0;
    padding:0;
  }
  .menu-item{
    position: relative;
    border-width: 1px 0 0 0;
    transition: all 0.08s ease-out;
    &:first-child{
      border-top-width:0;
      border-left-width:0;
    }
    &.dropdown > a::after{
      display: none;
    }
    a {
      display: inline-flex;
      align-items: center;
      transition: all 0.08s ease-out;
      text-align: center;
      //white-space: nowrap;
      //width: 100%;
      &::before {
        line-height: 1;
      }
    }
  }
}

@media only screen and (max-width: 470px){
  footer .links-menu .module-title{
    &::after{
      position: absolute;
      right: 0;
      margin:0 10px;
      content: "\eb85";
      font-family: icomoon, sans-serif;
    }
    &.closed{
      margin:0;
      &::after{
        content: "\eba0";
      }
      + .module-body{
        display: none;
      }
    }
  }
}

