@import 'variables.scss';

body {
  overflow-x: hidden;
}

/******************************
 Main Structure
*******************************/

.wrapper, .mega-menu-content, .site-wrapper > .container{
  max-width: 1024px;
}

.wrapper {
  margin: 0 auto;
  position: relative;
}

.site-wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  > .container {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    > .row {
      display: flex;
      flex-grow: 1;
    }
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
  > div {
    padding-left: 0;
    padding-right: 0;
  }
}

.site-wrapper > .container,
.site-wrapper > .container > .row {
  &::before, &::after {
    display: none;
  }
}

#content {
  width: 100%;
  .one-column &{
    max-width: calc(100% - 200px);
  }
  .two-column &{
    max-width: calc(100% - 200px * 2);
  }
}

.side-column {
  width: 100%;
  max-width: 200px;
}

.phone {
  .side-column {
    display: none !important;
  }
  #content {
    max-width: 100% !important;
  }
}

/******************************
 Page Titles
*******************************/

.title {
  position: relative;
  line-height: 1.2;
  margin:0;
  padding:0;
}

/******************************
 Footer
*******************************/

footer {
  clear: both;
  margin-top: auto;
}

/******************************
 Breadcrumbs
*******************************/

.breadcrumb {
  margin: 0;
  border-radius: 0;
  position: relative;
  padding: 0;
  background-color: transparent;
  width: 100%;
  margin: 0 auto;
  > li {
    position: relative;
    &:last-of-type{
      a {
        pointer-events: none;
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    top: 0;
    z-index: -1;
  }
}

.boxed-layout{
  .breadcrumb::before{
    display: none;
  }
}

/******************************
 Empty page
*******************************/

#content h1 + p:only-of-type:last-child{
  margin:0;
  padding:10px;
}
