@import '../variables.scss';


.module-header_notice{
  .module-body, .hn-body{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.hn-body{
  &::before{
    margin-right: 10px;
  }
}

.header-notice-close-button{
  margin-left: 10px;
}
