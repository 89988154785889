@import 'variables.scss';

/******************************
 Product Page
*******************************/

.product-info {
  display: flex;
  flex-wrap: wrap;
  > div{
    position: relative;
  }
  .product-details{
    display:flex;
    flex-direction:column;
    > .title{
      order: -50;
    }
    .product-blocks-top{
      order: 0;
    }
    .product-stats{
      order: 1;
    }
    .rating-page{
      order: 2;
    }
    .countdown-wrapper{
      order: 3;
    }
    .product-price-group{
      order: 4;
    }
    .product-options{
      order: 5;
    }
    .button-group-page{
      order: 6;
    }
    .product-blocks-bottom{
      order: 7;
    }
  }
}

/******************************
 Product Image
*******************************/

.product-image {
  position: relative;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  > div{
    order:5;
  }
  .main-image{
    overflow: hidden;
    cursor: zoom-in;
  }
  .swiper .swiper-pagination{
    display: none;
  }
  .additional-image{
    cursor: pointer;
  }
  .additional-images{
    overflow: hidden;
    .swiper-container{
      overflow: visible;
    }
  }
  .additional-images:not(.swiper) {
    display: flex;
    flex-wrap: wrap;
  }
  .product-labels {
    z-index: 1;
  }
  img {
    width: 100%;
    height: auto;
  }
  &.direction-vertical {

    flex-direction: row;

    .additional-images {
      height: 0;
      overflow: visible;

      .swiper-container {
        width: 100%;
        height: 100%;
        overflow:hidden;
      }

      .swiper-wrapper {
        flex-direction: column;
      }

      .swiper-buttons{
        width: 100%;
        height: 100%;
        > div{
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          max-width: 100%;
          &::before{
            transform: rotate(90deg);
          }
        }
        .swiper-button-next{
          top: auto;
          bottom: 0;
        }
      }
    }
  }
  &.position-left,
  &.position-top{
    .additional-images{
      order: -1;
    }
  }
}

//Cloud Zoom

.zm-viewer,
.zm-handler {
  pointer-events: none;
}

.zm-viewer{
  opacity:0;
  transition: opacity .25s .25s ease-in-out;
}

/******************************
 Product Stats
*******************************/

.product-info {
  .product-stats {
    position: relative;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    b{
      font-weight: normal;
    }
    ul{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin:0;
      overflow-x: auto;
    }
    li{
      margin-right: 20px;
      &::before {
        font-size: 7px;
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }
  .product-manufacturer {
    img{
      max-width: 150px;
    }
    > a{
      display: inline-block;
      > span {
        text-align: center;
        display: block;
      }
    }
  }
  .custom-stats{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    b{
      margin-right: 5px;
    }
    > div{
      display: flex;
      align-items: center;
      justify-content: center;
      &::before{
        font-size: 1.2em;
      }
      &:first-of-type{
        margin-right: 15px;
      }
    }
  }
}

/******************************
 Rating
*******************************/

.product-info{
  .rating{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > span{
      display: inline-flex;
      &:last-of-type{
        margin-right: 10px;
      }
    }
    div{
      a{
        display: inline-flex;
        margin:0 2px;
      }
    }
    b{
      font-weight: normal;
    }
    .review-links{
      margin: 0 5px;
    }
  }
  .rating-stars{
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

/******************************
 Price
*******************************/

.product-info{
  .price-group{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }
}

/******************************
 Default Options
*******************************/

.product-info{
  .product-options {
    .radio+.radio,
    .checkbox + .checkbox{
      margin:0;
    }
    h3{
      margin:0;
    }
    .img-thumbnail{
      border-radius:0;
      border-width:0;
      outline: none;
      padding:0;
      background-color: transparent;
    }
    .help-block{
      margin-bottom:0;
      &:empty{
        margin: 0;
      }
    }
    .option-value{
      margin: 0 5px;
    }
  }
}

/******************************
 Push Options
*******************************/

.product-info{
  .product-options{
    .push-option {
      input,
      input + img + .option-value{
        display: none;
      }
      .checkbox, .radio{
        padding:0 !important;
        margin:0 !important;
      }
      .option-value{
        margin:0;
      }
      > div{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        label{
          padding:0;
        }
        .option-value{
          padding: 0 7px;
        }
        .option-value, img{
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.075s ease-out;
          &:hover{
            cursor: pointer;
          }
        }
        > div{
          width: auto;
        }
      }
    }
    .option-price{
      margin-left: 5px;
    }
  }
}

/******************************
 Add to Cart
*******************************/

.product-info{
  .product-details {
    .button-group-page {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      bottom: 0;
      left: 0;
      right: 0;
      .alert {
        margin-top: 10px;
        padding: 5px 10px;
        width: 100%;
      }
      .extra-group {
        font-size: 0;
        display: flex;
        align-items: center;
        flex: 1;
      }
    }
    .wishlist-compare {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    .stepper-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-grow: 1;
    }
    .stepper {
      min-width: 60px;
      .form-control {
        padding-right: 25px;
        font-size: 1em;
      }
      span {
        i {
          width: 25px;
        }
      }
    }
  }
}

.popup-quickview{
  .main-image{
    cursor: default;
  }
  .extra-group{
    display: none !important;
  }
}

/******************************
 Product Tabs
*******************************/

.product-left{
  .product-blocks{
    margin-top: 20px;
  }
}

#tab-specification{
  .table{
    margin-bottom:0 !important;
  }
}

#review{
  > p{
    margin:0;
  }
  table{
    margin-bottom: 0 !important;
    &:not(:nth-last-child(2)){
      border-bottom-width: 0 !important;
    }
  }
  .rating{
    margin-top: 5px;
  }
}

#form-review{
  .rate{
    display: flex;
    align-items: center;
    min-height: 35px;
    span{
      position: relative;
      top: -1px;
    }
    > *{
      margin: 0;
      + *{
        margin: 0 0 0 10px;
      }
    }
  }
  .help-block{
    margin-bottom: 0;
  }
}


.tags-title{
  margin-right: 5px;
}
