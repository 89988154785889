@import '../variables.scss';

.nav-tabs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  border-width: 0 0 0 1px;
  &::before,
  &::after {
    display: none;
  }
  > li {
    margin: 0;
    display: flex;
    > a {
      width: 100%;
      display: flex;
      align-items: center;
      line-height: 1.25;
      margin: 0;
      border: 0;
      border-radius: 0;
      transition: all 0.07s ease-out;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background: transparent;
      &::before {
        margin-right: 5px;
        font-weight: normal;
      }
    }
    &:hover,
    &.active {
      > a {
        border: 0;
        background: transparent;
        &:hover,
        &:focus {
          background: inherit;
          border: 0;
        }
      }
    }
    &:first-child {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
      border-left-width: 0;
      > a {
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
      }
    }
    &:last-child {
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
      > a {
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
      }
    }
  }
}

.tab-content {
  position: relative;
  border-width: 1px;
}

.tab-pane {
  padding: 0;
  background: transparent;
  border-radius: inherit;
}

.nav-tabs > li {
  //border-width: 1px 1px 0 0;
  &.active {
    z-index: 10;
  }
}

.block-expand-overlay{
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 40px;
  position: absolute;
  bottom: 0;
  pointer-events: none;
}

.block-expand {
  padding: 2px 6px;
  font-size: 10px;
  pointer-events: auto;
  &::after{
    font-weight: inherit;
    -webkit-font-smoothing: initial;
  }
}

.block-content.block-expanded {
  max-height: 100% !important;
  overflow: visible !important;
  padding-bottom: 25px;
  transition: all 0s !important;
  + .block-expand-overlay{
    background:none !important;
  }
}
