@import '../variables.scss';

/******************************
 Accordion
*******************************/

.panel-group{
  margin-bottom:0;
  .panel{
    border-radius: 0;
    box-shadow: none;
    border:none;
    background: transparent;
    position: relative;
    z-index: 1;
    &:hover{
      z-index: 2;
    }
    + .panel{
      margin-top:0;
      //border-top:0;
    }
    &:first-child{
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }
    &:last-child{
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      &:not(.panel-active) > .panel-heading{
        border-bottom-width: 0;
      }
    }
  }
  .panel-heading{
    padding:0;
    transition: all 0.08s, border-radius 0s;
    border-radius:inherit;
    position: relative;
    z-index: 2;
    background: transparent;
    .panel-title{
      font-size: inherit;
      font-weight:inherit;
      position: relative;
      border-radius:inherit;
      transition: all 0.075s ease;
    }
    a{
      display: flex;
      align-items: center;
      i{
        display: none;
      }
      &::before{
        margin-right:5px;
      }
    }
    +.panel-collapse{
      position: relative;
      z-index: 1;
      > .panel-body{
        border-top: 0;
      }
    }
  }
  .panel-active{
    .panel-heading{
      //transition: all .5s;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      a{
        cursor: default;
      }
    }
    + .panel .panel-heading{
      border-top-width: 1px;
    }
  }

  .panel-body{
    padding:0;
  }
}

