@import '../variables.scss';

/******************************
 Full Search
*******************************/

.header-search {
  height: 100%;
  input {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    background: white !important;
    padding:0 12px;
    &:focus{
      box-shadow: none;
      outline: none;
    }
  }
  > span {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    position: static !important;
    .tt-hint {
      display: none;
    }
    input{
      border-radius: inherit;
    }
  }
  > button {
    &::before {
      content: "\f002";
      font-family: $icons;
    }
  }
  > *{
    order:5;
  }
}

.full-search #search{
  max-height: 100%;
  > .j-dropdown{
    display: block;
  }
}


/******************************
 Mini Search
*******************************/

.mini-search {
  .search-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    transition: all 0.075s ease-out;
    background-color: transparent;
  }
  #search{
    > .dropdown-menu {
      left: auto;
      right: 0;
      height: 40px;
      &::before {
        left: 100%;
        transform: translateX(-100%);
      }
    }
  }
  .header-search {
    display: flex;
    input{
      min-width: 150px;
    }
  }

  .search-button {
    min-width: 40px;
    font-size: 18px;
  }

  .search-categories-button {
    font-size: 13px;
    display: flex;
  }
}

/******************************
 Full Search
*******************************/

.full-search {
  #search {
    min-height: 30px;
    > .dropdown-menu {
      z-index:1;
      position: relative;
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1);
      padding: 0;
      margin: 0;
      min-width: initial;
      width: 100%;
      height: 100%;
      top: auto;
      &::before {
        display: none;
      }
    }
  }
  .header-search {
    display: flex;
    align-items: center;
    > span {
      flex-grow: 1;
    }
  }
}

/******************************
 Search Button
*******************************/

.search-button {
  padding: 0;
  min-width: 40px;
  height: 100%;
  border-radius: 0;
  font-size: 20px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  background-color: #ccc;
}

/******************************
 Categories Dropdown
*******************************/

.search-categories-button {
  padding: 0 10px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  display: block;
  cursor: default;
  &::after {
    position: relative;
    margin-left: 7px;
    content: "\f0dc";
    font-size: 11px;
    font-family: $icons;
  }
}

.search-categories {
  height: 100%;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  display: flex;
  align-items: center;
  transition: all 0.075s ease-out;
  background-color: #ccc;
  .j-dropdown {
    div {
      overflow: hidden;
    }
    ul {
      max-height: 350px;
      width: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: inherit;
      > li {
        font-size: 13px;
        width: 100%;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        position: relative;
        a{
          cursor: pointer;
          padding-left:15px;
        }
        &.category-level-1 a::before{
          display: none;
        }
        &.category-level-4 a{
          padding-left: 35px;
        }
        &.category-level-3 a{
          padding-left: 25px;
        }
        &.category-level-2 a{
          padding-left: 15px;
        }
      }
    }
  }
}

/******************************
 AutoSuggest
*******************************/

.search-results-empty {
  padding: 10px 15px;
}

.tt-menu {
  //display: block !important;
  width: 100%;
  top: auto !important;
  > div {
    max-height: 250px;
    overflow-y: auto;
    background-color: white;
    margin-left: auto;
    > div > a{
      transition: all 0.075s ease-out;
    }
  }
}

.tt-menu:not(.tt-empty){
  &::before {
    content: "";
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: white;
    left: 100%;
    transform: translateX(-150%);
  }
}

.search-result {
  position: relative;
  > a {
    padding: 8px;
    display: flex;
    border-width: 1px 0 0 0;
    color: #555;
    font-size: 14px;
    > img {
      max-height: 50px;
    }
    > span {
      flex-grow: 1;
      padding: 0 5px 5px 10px;
      display: flex;
      flex-direction: column;
    }
  }
  &:first-child {
    > a {
      border-top-width: 0;
    }
  }
  .button-group {
    align-self: center;
    margin-left: auto;
    //display: flex;
    display: none;
  }
  .product-name {
    color: #444;
    line-height: 1.2;
    margin-bottom: 2px;
  }
  .price-new {
    position: relative;
    top: 1px;
  }
  .price-old {
    color: white;
    text-decoration: line-through;
    font-size: 80%;
    background-color: #EA2349;
    padding: 1px 4px 2px 4px;
    border-radius: 3px;
    margin-right: 5px;
  }
  .tt-highlight {
    //font-weight:normal;
    color: blue;
  }
  &.view-more a{
    padding: 8px 15px;
    &::after{
      margin-left: 5px;
    }
  }
}
