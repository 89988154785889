@import '../variables.scss';

/******************************
 Mobile Header
*******************************/

.mobile-header,
.open-close,
.mobile-bar-group {
  display: none;
}

.mobile-header-active {
  .mobile-header {
    display: block;
  }
  .header {
    display: none;
  }
}

/******************************
 Header
*******************************/

header {
  z-index: 100;
}

.header {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  position: relative;
}

.header,
.dropdown-menu,
.top-menu {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

/******************************
 Logo
*******************************/

#logo {
  height: 100%;
  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.075s ease-out;
    > img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      position: relative;
      align-self: center;
    }
  }
  h1 {
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

/******************************
 Language & Currency
*******************************/

.language-currency {
  display: flex;
  align-items: center;
  height: 100%;
  pointer-events: none;

  > div:first-of-type:not(:only-of-type) {
    margin-right: 10px;
  }

  .dropdown{
    pointer-events: auto;
  }
  .dropdown-toggle {
    padding: 0;
  }

  .symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      align-self: center;
    }
  }

  button {
    background-color: transparent;
    margin: 0;
    width: 100%;
    white-space: nowrap;
    padding: 7px 10px;
  }

  a {
    white-space: nowrap;
    cursor: pointer;
  }

  .dropdown > button > span {
    display: flex;
    align-items: center;
  }

  .dropdown-menu {
    min-width: auto;
    > ul {
      > li {
        border-width: 1px 0 0 0;
        &:first-child {
          border-top-width: 0;
        }
        &::before {
          display: none;
        }
      }
    }
  }
}

#language,
#currency {
  display: flex;
  align-items: center;
  form > .dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.currency-symbol-title > .currency-symbol {
  width: 16px;
  height: 16px;
  background-color: #EA2349;
  color: white;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.language-flag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 18px;
  img {
    max-width: none;
    align-self: center;
  }
}

.currency-code,
.currency-code-dropdown {
  display: none;
}

.language-title,
.currency-title,
.currency-code,
.currency-title-dropdown,
.language-title-dropdown,
.currency-code-dropdown {
  margin-left: 5px;
}

.currency-select,
.language-select {
  display: flex;
  align-items: center;
}

/******************************
 Info Blocks
*******************************/

.module-info_blocks{
  .module-body{
    display: flex;
    flex-wrap: wrap;
  }
}

.info-blocks-wrapper {
  flex-grow: 1;
}

.info-block {
  display: flex;
  position: relative;
  transition: all 0.075s ease-out;
  height: 100%;
  .count-badge {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.info-block-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info-block-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  &::before {
    line-height: 1;
  }
}

/******************************
 DESKTOP HEADER STYLES
*******************************/

.desktop-header-active {

  header {
    &::before {
      width: 100%;
      height: 35px;
      position: absolute;
      top: 0;
      content: "";
      z-index: 1;
    }
    .top-bar {
      z-index: 4;
    }
  }

  /******************************
   Default
  *******************************/

  .header-default {

    > div {
      height: 100%;
    }

    .header-assets {
      flex-grow: 1;
      width: calc(100% - 250px);
    }

    .top-bar {
      height: 33.3333%;
      display: flex;
      align-items: center;
      .dropdown > a::after {
        margin-left: 0;
      }
    }

    .mid-bar {
      height: 33.3333%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .top-menu > ul > li + li {
        border-width: 0;
      }
    }

    .top-menu,
    .top-menu > ul,
    #cart,
    .desktop-cart-wrapper {
      height: 100%;
    }

    .full-cart {
      margin-left: auto;
    }

    .header-cart-group {
      display: flex;
      align-items: center;
    }
    .desktop-logo-wrapper {
      width: 250px;
    }
    .language-currency {
      padding: 0 10px;
    }
    .desktop-main-menu-wrapper {
      position: static;
      .main-menu > .j-menu, .main-menu-item {
        flex-grow: 1;
      }
    }
  }

  .header-default {
    .header-assets {
      flex-grow: 1;
      > div {
        height: 33.3333%;
      }
    }
    .cart-heading {
      justify-content: flex-end;
    }
    .desktop-main-menu-wrapper {
      .main-menu {
        display: flex;
      }
    }
    .top-bar {
      > .top-menu {
        flex-grow: 1;
        > ul {
          padding: 0;
          > li {
            flex: 1 0 0;
            > a {
              justify-content: center;
            }
          }
        }
      }
    }

    .secondary-menu {
      //margin: 0 15px;
    }

    .full-search, .full-search #search {
      height: 100%;
    }
    .top-menu-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      height: 100%;
    }
    &::before {
      z-index: -1;
    }
  }

  body:not(.is-sticky) {
    .header-default {
      .main-menu {
        > .menu-item {
          flex-grow: 1;
          height: 100%;
          > a {
            justify-content: center;
          }
        }
        > div:first-of-type {
          //border-left-width:0;
        }
        > div:last-of-type {
          //border-right-width: 0;
        }
      }
    }
  }

  /******************************
   Classic
  *******************************/

  .header-classic {
    flex-direction: column;
    > div {
      width: 100%;
    }
    .top-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .mid-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100px;
      > div {
        height: 100%;
        order: 5;
        display: flex;
        align-items: center;
        width: 33.3333%;
      }
      .top-menu{
        height: auto;
        margin:0 10px;
        > .j-menu{
          flex-wrap: nowrap;
        }
      }
    }
    #logo {
      width: 100%;
      a {
        width: 100%;
      }
    }
    .desktop-cart-wrapper {
      justify-content: flex-end;
    }
    .desktop-search-wrapper{
      min-width: 160px;
    }
    .header-cart-group {
      justify-content: flex-end;
    }
    .full-search #search {
      height: 35px;
      width: 100%;
    }
    #cart{
      flex:1;
      > a{
        justify-content: flex-end;
      }
    }
  }

  /******************************
   Mega
  *******************************/

  .header-mega {
    flex-direction: column;
    > div {
      width: 100%;
    }
    .top-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 35px;
    }
    .mid-bar {
      display: flex;
      align-items: center;
      height: 100px;
      > div {
        height: 100%;
      }
    }
    .mid-bar {
      > div {
        display: flex;
        align-items: center;
      }
    }
    #logo {
      margin: 0 auto;
      width: 100%;
      a {
        width: 100%;
        height: 100%;
      }
    }
    .desktop-search-wrapper {
      flex-grow: 1;
      justify-content: flex-end;
      display: flex;
      height: 100%;
      > div {
        width: 100%;
      }
    }
    .desktop-cart-wrapper {
      justify-content: flex-end;
      height: 100%;
      #cart, .cart-heading {
        height: 100%;
        background-color: #FDBA2C;
      }
    }
    .desktop-main-menu-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //position: relative; Static for sticky transition
    }
    .language-currency {
      pointer-events: none;
      button {
        pointer-events: auto;
      }
    }
  }

  /******************************
   Compact
  *******************************/

  .header-compact {
    #cart-total {
      display: none;
    }
    flex-direction: column;
    > div {
      width: 100%;
    }
    .mid-bar {
      display: flex;
      align-items: center;
      .desktop-main-menu-wrapper {
        position: static;
        &::before {
          display: none;
        }
        .main-menu {
          display: inline-flex;
        }
        .mega-fullwidth > .j-dropdown {
          padding-top: 14px;
          margin-top: -14px;
        }
      }
    }
    .desktop-logo-wrapper {
      height: 100%;
    }
    .desktop-main-menu-wrapper {
      height: auto;
      //flex-grow: 1;
      .main-menu {
        flex-wrap: nowrap;
        > .menu-item {
          > a {
            padding: 5px 10px;
            position: relative;
            z-index: 2;
          }
          > .dropdown-menu {
            z-index: 1;
          }
        }
      }
    }
    .header-cart-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
    }
    &::before {
      display: none;
    }
    .top-menu-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      padding: 0 10px;
    }
    .language-currency {
      padding: 0 10px;
    }
  }
  /******************************
   Slim
  *******************************/

  .header-slim {
    .desktop-main-menu-wrapper {
      height: 100%;
    }
    .header-cart-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .main-menu > .j-menu > .menu-item:not(:first-child) {
      display: none !important;
    }
    &::before {
      display: none;
    }
    .top-menu > ul {
      margin: 0;
    }
    .full-search {
      display: flex;
      align-items: center;
      flex-grow: 1;
      height: 100%;
      #search {
        width: 100%;
        height: 40px;
      }
    }
  }
}


/******************************
 Sticky Header
*******************************/

.desktop-header-active {
  .is-sticky {
    .desktop-main-menu-wrapper {
      transition: top 0.25s ease-out;
      position: fixed;
      top: 0;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      &::before {
        width: 100vw;
        left: 50%;
        margin-left: -50vw;
        //transform: translateX(-50%);
        height: 100%;
        position: absolute;
        //background: inherit;
        top: 0;
        z-index: -1;
      }
    }
    .header-default {
      .desktop-main-menu-wrapper::before {
        background: inherit;
      }
    }
  }

  &.full-sticky {
    header {
      top: 0;
      width: 100%;
      position: fixed;
    }
  }
}
