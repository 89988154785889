@import '../variables.scss';

/******************************
 Icons Menu
*******************************/

.icons-menu {
  > ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    > .menu-item {
      margin: 0;
      > a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 22px;
        position: relative;
        .links-text {
          font-size: .38em;
          margin-top: 2px;
          display: none;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          text-align: center;
        }
        .count-badge {
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
        }
        &::before {
          content: "\F05E";
          font-family: $icons;
        }
        &::after {
          display: none;
        }
      }
    }
  }
}
