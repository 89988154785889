

//Admin Bar
.admin-bar-wrapper {
  background: lightskyblue;
  z-index: 1000;
  position: relative;
  .admin-bar {
    margin: 0 auto;
    max-width: 980px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
  }
}


