@import '../variables.scss';

.module-newsletter{
  .module-body{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .btn {
    position: absolute;
    right: 0;
    z-index: 2;
    &::before{
      content: "\f0e0";
      font-family: $icons;
    }
  }
  .input-group + .checkbox label{
    display: block;
    margin-top: 5px;
    input{
      top:3px;
    }
  }
}

.newsletter-text{
  flex: 0 1 auto;
}

.newsletter-form{
  flex: 1 1 auto;
  width: 100%;
}

.side-column{
  .module-newsletter{
    .module-body{
      display: block;
    }
    .btn {
      span {
        display: none;
      }
    }
  }
  .newsletter-text{
    margin-bottom:10px;
  }
}
