@import 'variables.scss';

/******************************
 Description
*******************************/

.route-product-category {
  #content {
    display: flex;
    flex-direction: column;
  }
  #content-top {
    order: 0;
  }
  .page-title {
    order: -1;
  }
  .category-description {
    order: 1;
  }
  .refine-categories {
    order: 2;
  }
  .products-filter {
    order: 3;
  }
  .main-products {
    order: 4;
  }
  .pagination-results {
    order: 5;
  }
  #content-bottom {
    order: 100;
  }
  .buttons{
    order:50;
  }
}

.category-description {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

.category-image {
  float: left;
  img {
    max-width: 100%;
    height: auto;
  }
}

.category-text > p {
  margin-bottom: 0;
}

/******************************
 Subcategories
*******************************/

.refine-title {
  margin: 0;
  font-weight: normal;
  font-size: 18px;
  padding: .5em .7em;
}

.refine-items {
  display: flex;
  flex-wrap: wrap;
}

.refine-item {
  display: flex;
  a {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    transition: all 0.075s ease-out;
    img {
      margin-bottom: auto;
      max-width: 100%;
      height: auto;
      transition: all 0.05s ease-out;
      align-self: center;
    }
  }
}

.refine-name {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap:wrap;
  width: 100%;
  padding: 3px;
  transition: all 0.05s ease-out;
  .links-text {
    line-height: 1.2;
  }
}

.refine-grid {
  .refine-item {
    padding: 10px;
  }
}

.refine-links {
  .refine-item {
    a {
      background-color: transparent;
      padding: 0;
      img {
        display: none;
      }
    }
    &:not(:last-of-type) {
      &::after {
        margin: 0 7px;
      }
    }
  }
}

.refine-categories.refine-links {
  .refine-items {
    margin: 0;
    .refine-item {
      width: auto;
      padding: 0;
    }
  }
}

/******************************
 Products Sort Bar
*******************************/

.products-filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .select-group {
    display: flex;
    margin-left: auto;
    label, select {
      border-width: 0;
    }
    .input-group {
      display: inline-flex;
      align-items: center;
      height: auto;
      .input-group-addon {
        width: auto;
        display: flex;
        align-items: center;
        border-radius: 0
      }
    }
    .form-control {
      width: auto;
    }
    label {
      margin-bottom: 0;
    }
  }
  .input-group-addon,
  .form-control {
    height: 26px;
  }
}

.grid-list {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.view-btn {
  background-color: transparent;
  position: relative;
}

.compare-btn {
  display: flex;
  align-items: center;
  position: relative;
}

.sort-by {
  margin-right: 10px;
}

@media only screen and (max-width: 470px) {
  .products-filter {
    .select-group .input-group {
      .input-group-addon {
        display: none;
      }
      .form-control{
        max-width: 100px;
      }
    }
  }
}

.mac.webkit {
  .select-group {
    .input-group-addon {
      position: relative;
      z-index: 2;
    }
    .form-control {
      position: relative;
      left: -3px;
      z-index: 1;
    }
  }
}

/******************************
 Pagination
*******************************/

.pagination-results {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  &::before,
  &::after {
    display: none;
  }
  > div {
    width: auto;
    padding: 0
  }
  > div:first-child:not(:empty) + div {
    margin-left: auto;
  }
  .phone & {
    justify-content: center;
    > div {
      text-align: center;
      width: 100%;
    }
    .pagination {
      justify-content: center;
      > li {
        margin-bottom: 5px;
      }
    }
  }
}

.pagination {
  border-radius: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  > li {
    max-width: 50px;
    overflow: hidden;
    transition: all 0.08s ease-out;
    position: relative;
    &:not(:first-of-type) {
      border-width: 0 0 0 1px;
      border-color: transparent;
      border-style: solid;
    }
    > a,
    > span {
      border: none;
      line-height: 1;
      margin: 0;
      padding: 0;
      transition: all 0.05s ease-out;
      background: transparent;
    }
    > a:hover,
    > a:focus {
      background: transparent;
    }
    &:hover {
      z-index: 2;
    }
    &:first-child,
    &:last-child,
    &.prev, &.next {
      a {
        visibility: hidden;
        &::before {
          visibility: visible;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scaleX(1);
        }
      }
    }
    &:last-child,
    &.next {
      a::before {
        transform: translate(-50%, -50%) scaleX(-1);
      }
    }
  }
  > li:first-child {
    a, span {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
  > li:last-child {
    a, span {
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }
  > li.active {
    a, span, a:hover, span:hover {
      background: transparent;
    }
  }
}

/******************************
 Empty
*******************************/

.text-empty {
  padding: 10px;
}

/******************************
 Infinite Scroll
*******************************/

.ias-trigger,
.ias-spinner,
.ias-noneleft {
  width: 100%;
  text-align: center;
  i::before{
    font-size: 22px;
  }
}

.ias-trigger a {
  display: flex;
  align-items: center;
  justify-content: center;
}
