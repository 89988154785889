@import 'variables.scss';

/******************************
 Globals
*******************************/

html[dir="rtl"] {
  .text-right {
    text-align: left;
  }
  .form-control {
    text-align: right;
  }
  .fa-upload{
    margin-right: auto;
    margin-left: 5px;
  }
}

/******************************
 Form
*******************************/


/******************************
 Header Cart
*******************************/

html[dir="rtl"] {
  .cart-content {
    right: auto;
    left: 0;
    &::before {
      left: -100%;
      right: auto;
      transform: translateX(100%);
    }
    .table-striped tbody .td-image {
      padding-right: 10px;
    }
  }
  .full-cart {
    .cart-heading {
      padding-right: 0;
      padding-left: 40px;
      i {
        right: auto;
        left: 0;
      }
    }
  }
  .mini-cart {
    .count-badge {
      right: auto;
      left: 0;
    }
  }
}

/******************************
 Tabs
*******************************/

html[dir='rtl']{
  .nav-tabs{
    > li{
      > a{
        &::before{
          margin-right: auto;
          margin-left: 5px;
        }
      }
    }
  }
}

/******************************
 Countdown
*******************************/
html[dir='rtl']{
  .countdown {
    > div {
      &:first-of-type {
        border-left-width: 1px;
      }
      &:last-of-type {
        border-left-width: 0;
      }
    }
  }
}

/******************************
Stepper
*******************************/

html[dir='rtl']{
  .stepper .form-control {
    text-align: center;
  }
  .cart-group {
    .stepper {
      border-width: 1px 1px 1px 0;
      + .btn-cart {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-left-width: 1px;
        border-right-width: 0;
      }
    }
  }
}

/******************************
 Search
*******************************/

html[dir="rtl"] {
  .mini-search {
    #search > .dropdown-menu {
      left: 0;
      right: auto;
      &::before {
        left: -100%;
        transform: translateX(100%);
      }
    }
  }
  .search-categories {
    .j-dropdown {
      &::before {
        left: auto;
        right: 10px;
      }
    }
  }
  .search-categories-button {
    &::after {
      margin-right: 5px;
      margin-left: 0;
    }
  }
  .tt-menu {
    > div {
      margin-left: 0;
      margin-right: auto;
    }
  }
  .tt-menu:not(.tt-empty) {
    &::before {
      right: auto;
      left: 10px;
    }
  }
}

/******************************
 Top Menu
*******************************/


/******************************
 Main Menu
*******************************/

html[dir="rtl"] {
  .desktop-main-menu-wrapper {
    .main-menu {
      > div:first-of-type {
        border-right-width: 0;
        border-left-width: 1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
      }
      > div:last-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        border-left-width: 0;
      }
    }
  }
  .main-menu {
    .menu-item {
      a {
        &::before {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }
    .menu-label{
      right: auto;
      left: 0;
    }
  }
  .count-badge {
    margin-left: 0;
    margin-right: 5px;
  }
}

//Triangles

html[dir='rtl'].desktop-header-active{
  .main-menu{
    > .flyout > .dropdown-menu,
    .multi-level .dropdown-menu{
      &::before{
        left: auto;
        right: 10px;
      }
    }
  }
}



/******************************
 Multi-Level
*******************************/

html[dir="rtl"] {
  .multi-level {
    .dropdown-menu {
      ul li {
        .dropdown-menu {
          left: auto;
          right: 100%;
        }
        &.dropdown {
          padding-right: 0;
          padding-left: 10px;
          &::after {
            right: auto;
            left: 8px;
            transform: translateY(-50%) scale(-1);
          }
        }
      }
    }
  }
}

/******************************
 Mobile Header
*******************************/

html[dir='rtl']{
  .mobile-header:not(.mobile-2){
    .top-menu > ul > li:first-of-type{
      > .dropdown-menu{
        left: auto;
        right: -5px;
        &::before{
          left: auto;
          right: 5px;
        }
      }
    }
    .language-currency > div:last-of-type{
      .dropdown-menu{
        left: -5px;
        right: auto;
        &::before{
          left: auto;
          right: calc(100% - 5px);
          transform: translateX(100%);
        }
      }
    }
  }
}

/******************************
 Mobile Wrapper
*******************************/

html[dir='rtl'] {
  .mobile-wrapper {
    transform: translate3d(100%, 0, 0);
    right: 0;
  }
  .mobile-wrapper-open .mobile-wrapper {
    transform: translate3d(0, 0, 0);
  }
  &.mobile-header-active .cart-content {
    left: 0;
    right: auto;
    transform: translate3d(-100%, 0, 0);
  }
  .mobile-cart-open .cart-content {
    transform: translate3d(0, 0, 0);
  }
}

/******************************
 Mobile Main Menu
*******************************/

html[dir='rtl']{
  .mobile-main-menu-wrapper {
    .main-menu {
      .menu-item {
        .open-menu {
          right: auto;
          left: 0;
        }
      }
    }
  }
}

/******************************
 Headers
*******************************/

html[dir='rtl'].desktop-header-active {
  .header-mega {
    .header-cart-group {
      margin-right: auto;
      margin-left: 0;
    }
    .info-block {
      .count-badge {
        right: auto;
        left: 0;
      }
    }
  }
  .header-compact {
    .language-currency + .top-menu {
      padding-left: 0;
      padding-right: 10px;
    }
    .desktop-main-menu-wrapper {
      height: auto;
      flex-grow: 1;
    }
  }

  //Sticky
  .is-sticky {
    .header {
      .desktop-main-menu-wrapper {
        left: 50%;
        width: 100%;
        max-width: 1100px;
        transform: translateX(-50%);
        .main-menu {
          margin-right: 0;
          margin-left: auto;
        }
      }
    }
    //Boxed
    &.boxed-sticky {
      .desktop-main-menu-wrapper {
        &::before {
          max-width: 1100px;
          left: 0;
          margin-left: 0;
          clip: rect(0, 1100px, 100px, 0);
        }
      }
    }
  }
  &.mobile-menu-active{
    .is-sticky{
      .menu-trigger{
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}



/******************************
 Flyout
*******************************/

html[dir="rtl"] {
  .flyout-menu {
    .menu-item {
      padding-right: 0;
      padding-left: 10px;
      a {
        &::before {
          margin-right: 0;
          margin-left: 3px;
          left: auto;
          right: -2px;
        }
      }
      .j-dropdown {
        left: auto;
        right: 100%;
      }
      &.dropdown > a::after {
        right: auto;
        left: -3px;
        transform: translateY(-50%) scale(-1);
      }
    }
  }
}

/******************************
 Accordion Menu
*******************************/

html[dir="rtl"] {
  .accordion-menu {
    .open-menu {
      right: auto;
      left: 8px;
    }
    .menu-item {
      > a {
        &::before {
          margin-right: 0;
          margin-left: 5px;
        }
      }
      > div .menu-item a::before {
        margin-right: 0;
      }
    }
  }
}

/******************************
 Header Notice
*******************************/

html[dir='rtl']{
  .hn-body{
    &::before{
      margin-right: auto;
      margin-left: 10px;
    }
  }

  .header-notice-close-button{
    margin-left: auto;
    margin-right: 10px;
  }
}

/******************************
 Newsletter
*******************************/

html[dir='rtl'] {
  .module-newsletter {
    .module-body {
      .input-group-btn {
        right: auto;
        left: 0;
      }
    }
  }
}

/******************************
 Language & Currency
*******************************/

html[dir="rtl"] {
  .language-currency {
    > div {
      &:first-of-type:not(:only-of-type) {
        margin-right: 0;
      }
      + div {
        margin-left: 0;
        margin-right: 12px;
      }
    }
  }

  .language-title,
  .currency-title,
  .currency-code,
  .currency-title-dropdown,
  .language-title-dropdown,
  .currency-code-dropdown{
    margin-left: auto;
    margin-right: 5px;
  }
}

/******************************
 Links Menu
*******************************/

html[dir='rtl'] {
  .links-menu {
    .menu-item {
      &:first-child {
        border-right-width: 0;
      }
      a {
        &::before {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }
  }
}

/******************************
 Products Filter
*******************************/

html[dir="rtl"] {
  .products-filter {
    .select-group {
      margin-left: 0;
      margin-right: auto;
    }
  }
  &.mac.webkit {
    .select-group {
      .form-control {
        left: auto;
        right: -3px;
      }
    }
  }
  .grid-list .compare-btn::before {
    margin-right: 0;
    margin-left: 5px;
  }
}

/******************************
 Mobile Bottom Menu
*******************************/

html[dir='rtl'] .bottom-menu > ul > li + li {
  border-width: 1px 0 0 0;
}

/******************************
 Pagination
*******************************/

html[dir='rtl'] {
  .pagination-results {
    > div:first-child:not(:empty) + div {
      margin-left: 0;
      margin-right: auto;
      white-space: nowrap;
    }
  }

  .pagination {
    > li {
      &:not(:first-of-type) {
        border-width: 0 1px 0 0;
      }
      &:first-child,
      &:last-child,
      &.prev, &.next {
        a {
          &::before {
            transform: translate(-50%, -50%) scaleX(-1);
          }
        }
      }
      &:last-child,
      &.next {
        a::before {
          transform: translate(-50%, -50%) scaleX(1);
        }
      }
      &:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        a, span{
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      &:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        a, span{
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}
