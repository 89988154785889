@import '../variables.scss';

.mobile-header-active .main-menu.main-menu-3 > .j-menu .main-menu-item-1 > a::before{
  display: none;
}

/******************************
 Mobile Wrapper
*******************************/

.mobile-container {
  display: none;
  position: fixed;
  z-index: 5000;
  max-width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translate3d(-100%, 0, 0);
  transition: all .35s ease-in-out;
  background-color: white;

  &.mobile-cart-content-container {
    transform: translate3d(100%, 0, 0);
    left: auto;
    right: 0;
  }
}

/******************************
 Open-Close Animations
*******************************/

//Menu
.mobile-main-menu-container-open {
  .mobile-main-menu-container {
    display: block;
    &.animating {
      transform: translate3d(0, 0, 0);
    }
  }
}

//Cart
.mobile-cart-content-container-open {
  .mobile-cart-content-container {
    display: block;
    &.animating {
      transform: translate3d(0, 0, 0);
    }
  }
}

//Filter
.mobile-filter-container-open {
  .mobile-filter-container {
    display: block;
    &.animating {
      transform: translate3d(0, 0, 0);
    }
  }
}

// Site Overlay
.mobile-header-active .site-wrapper{
  cursor: pointer;
  &::before {
    content: "";
    position: fixed;
    z-index: 3000;
    left: 0;
    top: -200px;
    width: 100%;
    height: calc(100vh + 200px);
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-out;
  }
}

// Close Overlay
.mobile-overlay{
  overflow: hidden;
  .site-wrapper::before {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    background: rgba(0, 0, 0, .5);
  }
}

/******************************
 Mobile Wrapper Header
*******************************/

.mobile-wrapper-header {
  position: relative;
  width: 100%;
  height: 40px;
  z-index: 1;
  display: flex;
  align-items: center;
  min-width: 0;
  span {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: 17px;
    flex: 1;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  a {
    height: 100%;
    width: 40px;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    cursor: pointer;
    &::before {
      content: "\e5cd";
      font-family: $icons;
    }
  }
}

/******************************
 Mobile Cart
*******************************/

.mobile-cart-content-wrapper{
  padding-bottom: 40px;
}

.mobile-header-active {
  .cart-content {
    min-width: initial;
    display: block;
    height: 100%;
    ul{
      height: 100%;
    }
    .cart-products {
      max-height: 100%;
      height: 100%;
      -webkit-overflow-scrolling: touch;
    }
    .cart-totals {
      position: relative;
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
}

/******************************
 Mobile Filter
*******************************/

.mobile-filter-wrapper {
  height: 100%;
  padding-bottom: 40px;
  .module-filter{
    padding:15px;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    .module-title{
      display: none;
      padding:0;
      margin:0;
    }
    .panel-body{
      max-height: 100%;
      overflow:visible;
    }
  }
}

.mobile-filter-container-open{
  .journal-loading-overlay{
    position: fixed;
    top: 40px;
    left: 0;
    width: 85%;
  }
}

/******************************
 Mobile Main Menu
*******************************/

.mobile-main-menu-wrapper{
  height: 100%;
  padding-bottom: 40px;

  .main-menu {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    .j-menu > * {
      box-shadow: none !important;
    }
    .dropdown::after {
      display: none !important;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      box-shadow: none !important;
    }
    .menu-label {
      position: relative;
      margin: 0 5px !important;
    }
    .main-menu {
      border-width: 0;
      background-color: transparent;
      .menu-item {
        padding: 0;
        //border-width: 1px 0 0 0;
        .open-menu {
          min-width: 40px;
          height: 100%;
          right: 0;
          border-radius: 0
        }
        > a {
          position: relative;
          height: 40px;
          justify-content: flex-start;
          padding: 0 10px;
          display: flex;
          align-items: center;
          &::after {
            display: none;
          }
        }
      }

      .dropdown-menu {
        padding: 0;
        margin: 0;
        position: relative;
        opacity: 1;
        visibility: visible;
        transform: translateY(0) scale(1);
        width: 100%;
        display: none;
        > ul {
          box-shadow: none;
          li {
            > a {
              margin-bottom: 10px;
            }
            .dropdown-menu {
              left: 0;
              box-shadow: none;
            }
          }
        }
        &::before {
          display: none;
        }
      }
      .open > .dropdown-menu {
        display: block;
      }
    }
    .multi-level .dropdown-menu ul {
      box-shadow: none;
      li.dropdown {
        display: table;
        width: 100%;
      }
    }
    .mega-menu .main-menu-item > a + div {
      border-width: 1px 0 0 0;
      border-color: inherit;
      border-style: inherit;
    }
    > .main-menu {
      > div {
        &:first-of-type {
          border-top-width: 0;
        }
        &:last-of-type {
          border-bottom-width: 1px;
        }
      }
    }
    .j-menu {
      border-style: none !important;
    }
    .flyout > .collapse > div > .j-menu > li:first-child > a {
      border-width: 1px 0 0 0;
    }
  }
}















