@import 'variables.scss';

/********************e**********
 Post Grid/List
*******************************/

.post-grid:not(.swiper-wrapper){
  display: flex;
  flex-wrap: wrap;
}

.post-layout {
  display: flex;
  position: relative;
}

.post-thumb {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
  position: relative;
  transition: all 0.075s ease-out;
  //overflow: hidden; //interferes with image and details shadow
  .image {
    position: relative;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: auto;
        pointer-events: none;
        transition: all 0.075s ease-out;
      }
    }
  }
  &:hover {
    z-index: 2;
  }
  .caption {
    display: flex;
    flex-direction: column;
    flex: 1000 0 auto;
    position: relative;
    > *{
      order: 50;
    }
  }
  .name {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.3;
    margin: 0;
    display: flex;
    align-items: center;
    max-width: 100%;
    a {
      display: block;
    }
  }
  .description {
    margin: 0 0 auto 0;
    line-height: 1.3;
  }

  .post-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 11px;
  }
}

//Buttons
.post-layout {
  .button-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .btn-text {
    display: none;
  }
}

.post-grid .button-group{
  margin-top: auto !important;
}

.post-list{
  .image{
    max-width: 200px;
  }
  .post-thumb{
    flex-direction: row;
  }
}

/******************************
 Post Page
*******************************/

.post-image{
  break-inside: avoid;
  img{
    height: auto;
  }
}

.post-stats{
  span{
    align-items: center;
    margin-right: 8px;
    &::before{
      margin-right: 5px;
    }
  }
}

.post-details{
  column-fill: balance;
  blockquote{
    padding:0;
    margin:0;
    border: none;
    font-size: inherit;
  }
}

/******************************
 Comments
*******************************/

.blog-post{
  .comment{
    p{
      margin-top: 5px;
    }
  }
  .success{
    background: lightgreen;
    padding:5px 10px;
    margin-bottom: 15px;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
}

.post-comment{
  position: relative;
  .user-name{
    padding-right: 50px;
  }
}

.user-data{
  margin-top: 3px;
  ::before{
    margin-right:5px;
  }
  span{
    display: inline-flex;
    align-items: center;
    max-width: 100%;
  }
  div span{
    margin-right: 5px;
  }
  a{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.user-avatar{
  float: left;
  position: relative;
  z-index: 2;
}

.reply-form{
  margin-top: 20px;
  max-width: 50%;
}

.reply-btn{
  position: absolute;
  top: 10px;
  right: 10px;
}

/******************************
 Feed
*******************************/

.blog-feed{
  position: absolute;
  right: 10px;
  top: 13px;
  font-size: 13px;
  font-weight: normal;
  span{
    margin: 0 5px;
  }
}

html[dir='rtl']{
  .blog-feed{
    right: auto;
    left:10px;
  }
}

/******************************
 Modules
*******************************/

.module-blog_categories{
  .module-body{
    display:flex;
    flex-direction:column;
  }
  a{
    display: flex;
    align-items: center;
    transition: all 0.07s ease-out;
    + a{
      border-width: 1px 0 0 0;
    }
  }
  span{
    margin:0 5px;
  }
}

/******************************
 Search
*******************************/

.box-search{
  display: flex;
  align-items: center;
}

/******************************
 Comments
*******************************/

.module-blog_comments{
  .module-body > div + div{
    border-width: 1px 0 0 0;
  }
  .module-item{
    display: flex;
    > div{
      flex: 1;
      padding:0 7px;
    }
  }
}
