@import 'variables.scss';

/******************************
 Wishlist
*******************************/

.route-account-wishlist {
  .table {
    margin-bottom: 0;
  }
  .table-responsive {
    table tr td {
      &:nth-of-type(2) {
        min-width: 175px;
      }
    }
  }
}

[class*="route-account-"]:not(.route-account-address){
  table .text-right{
    .btn{
      min-width: 35px;
      min-height: 35px;
      padding:0;
      margin:2px;
      i{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &::before{
          font-size: 16px;
        }
      }
    }
  }
  h1 + p{
    margin-bottom: 15px;
  }
}

/******************************
 Cart Page
*******************************/

.cart-total {
  display: flex;
  justify-content: flex-end;
  .table {
    margin: 0 !important;
    td + td {
      width: 15%;
      min-width: 130px;
    }
  }
}

.route-checkout-cart {
  .table-responsive {
    table {
      tbody {
        .td-qty {
          .input-group {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
          }
          .input-group-btn {
            //display: flex;
            width: auto;
          }
        }
      }
      tr td {
        min-width: 90px;
        &:nth-of-type(2) {
          min-width: 175px;
        }
      }
    }
  }
  .cart-panels p{
    margin-bottom: 10px;
  }
}

#content {
  table td {
    vertical-align: middle;
  }
}

/******************************
 Default Checkout
*******************************/

.route-checkout-checkout {
  .table-responsive {
    table tr td {
      min-width: 90px;
      &:nth-of-type(1) {
        min-width: 175px;
      }
    }
  }
  #payment {
    > div > div > div {
      //max-width: 235px;
    }
    .col-sm-3 {
      max-width: 110px;
      padding-right: 0;
      float: left;
    }
  }
}

.phone.route-checkout-checkout {
  #payment {
    > div > div > div {
      max-width: 100%;
    }
  }
}

//Paypal Iframe
.table-responsive > iframe {
  margin: 0 !important;
  padding-top: 20px;
  width: 100%;
}

/******************************
 Sitemap
*******************************/

.route-information-sitemap {
  #content > .row {
    display: flex;
    flex-wrap: wrap;
    &::before{
      display: none;
    }
    > div {
      flex-grow: 1;
      > ul {
        padding: 0;
        ul{
          padding-left: 20px;
        }
        li {
          list-style: none;
          margin-top: 5px;
          a {
            &::before {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

/******************************
 Contact
*******************************/

.route-information-contact {
  .our-location {
    a > i {
      margin-right: 5px;
    }
    background: transparent;
    border: none;
    box-shadow: none;
    .panel-body {
      padding: 0;
    }
  }
  .other-stores{
    margin-bottom: 20px;
  }
}

/******************************
 Manufacturer
*******************************/

.route-product-manufacturer {
  .brand {
    h2 {
      margin-top: 15px;
    }
    .row {
      display: flex;
      flex-wrap: wrap;
      margin: -5px;
      &::before,
      &::after {
        display: none;
      }
      > div {
        padding: 5px;
        display: flex;
        width: 20%;
      }
    }
  }
  #content > h1 + p {
    padding: 0;
    margin-bottom: 10px;
  }
  .manufacturer {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > div {
      padding: 0 15px 15px 0;
    }
  }
}

.image-card {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  transition: all 0.15s ease;
  img {
    margin-bottom: 5px;
  }
  > span {
    width: 100%;
    text-align: center;
    margin-top: auto;
  }
}

/******************************
 Compare
*******************************/

.route-product-compare {
  .compare-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 6px;
    .btn {
      flex-grow: 1;
      margin: 4px;
    }
  }
  table tbody:last-of-type tr:last-child td {
    border-top-width: 1px;
    padding: 0;
  }
}

/******************************
 Search
*******************************/

.route-product-search {
  #content {
    .search-form {
      .inputs {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      input {
        width: auto;
        flex-grow: 1;
        max-width: 100%;
        margin-bottom: 10px;
      }
      select {
        max-width: 40%;
        margin-bottom: 10px;
        margin-left: 20px;
      }
    }
    > h2:last-child {
      display: none !important;
    }
    > h2:last-of-type {
      margin-top: 15px
    }
    > .checkbox {
      margin-bottom: 5px;
    }
  }
}

