@import '../variables.scss';


/******************************
 General
*******************************/



//Scrollbar desktop fix
.no-touchevents{
  &.popup-open{
    overflow-y: hidden;
    body{
      overflow-y: scroll;
    }
  }
}
.touchevents.popup-open,
.touchevents.popup-open body{
  overflow: hidden;
}

.popup-wrapper{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:999998;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  -webkit-overflow-scrolling:touch;
}

.popup-bg{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  z-index:9997;
  opacity:0;
  visibility: hidden;
  transition: all 0.4s ease-out;
}

.popup-container{
  opacity:0;
  visibility: hidden;
  transform: translate3d(0,0,0) scale(.8);
  transition: all 0.2s ease-out, margin 0s;
  position: relative;
  max-width: 100%;
  z-index:9999;
  pointer-events: none;
}

.popup-open{
  .popup-wrapper{
    pointer-events: auto;
    overflow-y: scroll;
  }
  .popup-body{
    pointer-events: auto;
  }
  .popup-bg{
    opacity:1;
    visibility: visible;
  }
  .popup-container{
    opacity: 1;
    visibility: visible;
    transform: translate3d(0,0,0) scale(1);
  }
}

.popup-close{
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  font-size: 18px;
  background: white;
  z-index: 2;
  pointer-events: auto;
  z-index: 100;
  &:hover{
    background: $blue;
  }
  &::before{
    content: "\e5cd";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/******************************
 Header
*******************************/

.popup-header{
  position: relative;
}

/******************************
 Body
*******************************/

.popup-body{
  overflow: hidden;
  img{
    vertical-align: bottom;
  }
  .grid-cols{
    max-width: 100%;
  }
  .product-thumb .btn-quickview{
    display: none !important;
  }
}

.popup-inner-body{
  position: relative;
  overflow: hidden;
  display:flex;
  flex-direction:column;
  border-radius: inherit;
  max-height: 100vh;
}

.popup-content{
  overflow-y: auto;
  flex-grow: 1;
  > p {
    margin:0;
  }
  img{
    height: auto;
  }
}


/******************************
 Footer
*******************************/

.popup-footer{
  text-align: center;
  position: relative;
  .btn + .btn{
    margin-left:10px;
  }
}

.popup-dont-show{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  input{
    float: left;
    margin:0;
  }
  span{
    margin:0 5px;
  }
}


/******************************
 Iframe / Quickview
*******************************/

.popup{
  body,
  #content,
  .site-wrapper > .container > .row{
    background:none !important;
    margin: 0 !important;
  }
  #content,
  .site-wrapper > .container > .row{
    padding:0 !important;
  }
  .site-wrapper{
    min-height: 100%;
  }
  .wrapper,
  .boxed-layout .site-wrapper,
  .site-wrapper,
  .grid-cols{
    max-width: 100%;
  }
}

.popup-options{
  .product-info .product-details{
    .product-options > .options-title{
      display: flex;
      + .form-group{
        margin-top: 10px;
      }
    }
    .stepper-group{
      width: 100%;
    }
    .button-group-page{
      position: fixed;
      margin: 0;
      bottom: 0;
      z-index: 99;
      box-shadow: 0 -5px 20px 0 rgba(0,0,0,.1);
    }
    padding-bottom: 60px;
  }
}

.popup-inner-body{
  > iframe{
    width: 100%;
    height: 100%;
  }
}

.touchevents.popup{
  .site-wrapper{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .popup-content{
    padding:20px;
  }
}


/******************************
 Login/Register
*******************************/

html.popup-login {
  .breadcrumb,
  .login-box .col-sm-6:first-child {
    display: none;
  }
  .form-horizontal {
    margin-bottom: 0;
  }
  .login-box > div {
    flex: 1;
  }
}

html.popup-register {
  .breadcrumb,
  .page-title,
  .page-title + p {
    display: none;
  }
  .form-horizontal {
    margin-bottom: 0;
  }
}



/******************************
Scrollbar
*******************************/

//.popup-open .popup-wrapper::-webkit-scrollbar {
//  width: 1em;
//}
