@import 'variables.scss';

/******************************
 Journal Grid
*******************************/

.grid-rows{
  display: flex;
  flex-direction: column;
}

.grid-row{
  position: relative;
}

.grid-cols{
  display: flex;
  flex-wrap: wrap;
  margin:0 auto;
  height: 100%;
}

.grid-col{
  width: 100%;
  position: relative;
}

.module{
  position: relative;
}

.side-column > .grid-rows > .grid-row > .grid-cols > .grid-col{
  width: 100% !important;
}

.grid-items{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.grid-item{
  width: 100%;
}

.grid-row::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/******************************
 Column Dimensions
*******************************/

.desktop{
  #top {
    .grid-col{
      &::before {
        pointer-events: none;
        position: absolute;
        padding: 0 4px;
        font-size: 12px;
        top: 0;
        left: 0;
        background-color: palegreen;
        z-index: 10;
      }
      &:hover::before{
        content: attr(data-dimensions);
      }
    }
  }

  #top .grid-cols > div:only-of-type::before{
    display: none;
  }
}


/******************************
 Demo
*******************************/

//#content {
//  .grid-row-1 {
//    background-color: aliceblue;
//  }
//  .grid-row-2 {
//    background-color: antiquewhite;
//  }
//  .grid-col-1 {
//    background-color: antiquewhite;
//  }
//  .grid-col-2 {
//    background-color: azure;
//  }
//  .grid-col-3 {
//    background-color: lightgreen;
//    > div:first-of-type{
//      background-color: lavender;
//    }
//  }
//}
