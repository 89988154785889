@import '../variables.scss';

/******************************
Fixed Bottom Menu
*******************************/

.bottom-menu {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: white;
  z-index: 10;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-width: 1px 0 0 0;
  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  > ul {
    display: flex;
    align-items: center;
    > li {
      flex-grow: 1;
      > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 10px;
        min-width: 50px;
        transition: all 0.075s ease-out;
        position: relative;
        &::before {
          font-size: 1.5em;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      + li {
        border-width: 0 0 0 1px;
      }
      .links-text {
        white-space: nowrap;
        position: relative;
        top: -2px;
      }
      .count-badge{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(50%, -100%);
        margin:0;
      }
    }
  }
}


