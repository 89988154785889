@import 'variables.scss';

/******************************
 Quick Checkout
*******************************/

.quick-checkout-wrapper {
  > div {
    display: flex;
    flex-wrap: wrap;
    > div {
      flex-grow: 1;
      max-width: 100%;
    }
    .right {
      .checkout-section:last-child {
        margin-bottom: 0;
      }
    }
  }

  .section-body > div + .radio {
    margin-top: 8px;
  }

  #input-cc-cvv2{
    max-width: 111px;
  }
  .payment-address{
    .checkbox{
      margin-top: 15px;
    }
  }
  .well{
    margin-bottom: 0;
    box-shadow: none;
    border: none;
    padding: 0;
    background: transparent;
  }
  .form-group:last-child{
    margin-bottom: 0;
  }
  .checkout-section {
    .login-options {
      margin-bottom: 0;
    }
    .login-form{
      > div:first-of-type {
        margin-top: 10px;
      }
    }
  }

  .shipping-payment {
    display: flex;
    flex-wrap: wrap;
    .section-payment {
      flex-grow: 1;
    }
  }

  .cart-section{
    table{
      margin-bottom: 0 !important;
    }
  }

  .confirm-section{
    textarea{
      margin-bottom: 10px;
    }
  }

  .login-active .checkout-section:not(.section-login) {
    opacity: .4;
    pointer-events: none;
  }

  // Spinner
  .quick-checkout-spinner {
    display: flex;
    justify-content: center;
  }
  // Payment details
  .payment-pagseguro,
  .payment-skrill,
  .payment-cod,
  .payment-payza,
  .payment-pp_standard,
  .payment-pp_express,
  .payment-coinbase,
  .payment-stripe,
  .payment-instamojo,
  .payment-twocheckout {
    display: none !important;
  }

  #payment{
    > legend{
      display: none;
    }
  }

  // Payment buttons
  .quick-checkout-payment .buttons {
    display: none !important;
  }
}

//Checkout
.route-journal3-checkout {
  .panel-group {
    padding: 10px;
  }
  .form-group label {
    display: none;
  }
  textarea {
    width: 100%;
    height: 100px;
  }
}

@media only screen and (max-width: 760px){
.confirm-section{
  .radio label, .checkbox label{
    display: block;
  }
  .radio input, .checkbox input {
    margin: 4px 0 0 -20px;
  }
}
}
