@import '../variables.scss';


.category-grid{
  display: flex;
  &:not(.swiper-wrapper){
    flex-wrap: wrap;
  }
}

.manufacturer-grid{
  display: flex;
  &:not(.swiper-wrapper){
    flex-wrap: wrap;
  }
}

.category-thumb,
.manufacturer-thumb{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  .caption{
    width: 100%;
    display:flex;
    flex-direction:column;
  }
  .description{
    flex-grow: 1;
  }
  .button-group{
    margin-top: auto;
  }
  .image{
    width: 100%;
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
    }
    img{
      width: 100%;
      height: auto;
    }
  }
}

.manufacturer-thumb{
  .caption {
    margin-top: auto;
  }
  .name a{
    width: 100%;
  }
}


