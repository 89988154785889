@import '../variables.scss';

/******************************
 Grid
*******************************/


.manufacturer-grid{
  display: flex;
  &:not(.swiper-wrapper){
    flex-wrap: wrap;
  }
}
