@import '../variables.scss';

.module-banners{
  .module-body {
    display: flex;
    flex-wrap: wrap;
  }
  .module-item{
    > a{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 100%;
      transition: all 0.075s ease-out;
      -webkit-backface-visibility: hidden;
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
        transition: all 0.2s ease-out;
      }
      &::before{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;
        transition: all 0.1s ease-out;
        opacity: 0;
        z-index: 2;
      }
      &::after{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) scale(.5);
        transition: all 0.1s ease-out;
        opacity: 0;
        font-size: 35px;
        color: white;
        z-index: 4;
      }
      &:hover{
        img{
          //transform: scale(1.1);
        }
        &::before{
          opacity: 1;
        }
        &::after{
          transform: translate3d(-50%, -50%, 0) scale(1);
          opacity: 1;
        }
        .banner-caption{
          transform: translate3d(0, 0, 0) scale(1);
          opacity: 1;
        }
      }
    }
  }
  .banner-caption{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding:10px;
    transform: translate3d(0, 10px, 0) scale(1);
    transition: all 0.1s ease-out;
    opacity: 0;
    color: white;
    line-height: 1.3;
    z-index: 3;
  }
}
