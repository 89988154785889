@import '../variables.scss';

/******************************
 Main Menu
*******************************/

.desktop-main-menu-wrapper {
  position: static;

  &::before {
    content: "";
    position: absolute;
    width: 100vw;
    left: 50%;
    margin-left: -50vw;
    bottom: 0;
    z-index: -1;
    height: 100%;
  }

  .main-menu {
    height: 100%;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    //second row items normal width
    &::after {
      //content: '';
      flex-grow: 99;
    }
    .main-menu-item {
      display: flex;
      flex-direction: column;
      height: 100%;
      > a {
        border-width: 0 0 0 1px;
        justify-content: center;
      }
      &:first-of-type {
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        > a {
          border-left-width: 0;
          border-radius: inherit;
        }
      }
      &:last-of-type {
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        > a {
          border-right-width: 0;
          border-radius: inherit;
        }
      }
      &.dropdown {
        > a {
          &::after {
            transform: rotate(90deg);
            margin-top: 2px;
            display: none;
          }
        }
      }
    }
  }

  &.menu-stretch {
    .main-menu {
      width: 100%;
      flex-wrap: nowrap;
      > ul{
        width: 100%;
        > li{
          flex-grow: 1;
        }
      }
      + .main-menu {
        display: none;
      }
    }
  }

  &.menu-center {
    text-align: center;
    .main-menu {
      margin: 0 auto;
      display: inline-flex;
      + .main-menu {
        display: none;
      }
    }
  }

  &.has-menu-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

/******************************
 Flyout
*******************************/

.main-menu > .flyout {
  .flyout-menu-item:first-child,
  .flyout-menu-item:first-child > a {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .flyout-menu-item:last-child,
  .flyout-menu-item:last-child > a {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  > .dropdown-menu::before {
    left: 10px;
  }
}

/******************************
 Mega Menu
*******************************/

$pad: 20px;

.desktop-header-active {
  .main-menu {
    .mega-menu {
      &.mega-custom {
        position: relative;
      }
      .mega-menu-content{
        max-height: calc(100vh - 100px);
      }
    }
    .menu-fullwidth {
      .mega-menu-content {
        max-width: 100%;
      }
    }
    .menu-fullwidth,
    .mega-fullwidth {
      > .j-dropdown {
        padding-top: 0;
        &::before {
          display: none;
        }
      }
    }
  }

  //Tall menu animation delay

  //.main-menu > .mega-menu {
  //  > .dropdown-menu {
  //    height: 0;
  //    overflow: hidden;
  //  }
  //  &.animation-delay {
  //    > .dropdown-menu {
  //      height: auto;
  //      overflow: initial;
  //    }
  //  }
  //}
}

/******************************
 //Multi-Level Columns
*******************************/

//.multi-level > .dropdown-menu > ul {
//  max-height: 150px;
//  display: flex;
//  flex-direction: column;
//  flex-wrap: wrap;
//  > li:last-of-type:nth-of-type(odd) {
//    border-bottom-width: 1px;
//  }
//  > li:nth-child(2n+1) {
//    border-top-width: 0; // Removes border on last item in column
//  }
//}

/******************************
 Menu Open Site Overlay
*******************************/

@keyframes overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.desktop-header-active {
  .main-menu > .first-dropdown {
    &::before {
      z-index: 0;
      position: absolute;
      width: 200vw;
      min-height: calc(100vh - 250px);
      top: 100%;
      left: -100vw;
      pointer-events: none;
      animation: overlay .3s ease;
      opacity: 0;
      visibility: hidden;
      transition: all 0.15s ease-in-out;
      transition-delay: .15s;
      content: "";
      display: none !important;
    }
  }
  .menu-open .main-menu > .first-dropdown::before {
    opacity: 1;
    visibility: visible;
  }
  .menu-hover .main-menu > .first-dropdown::before {
    opacity: 0;
    visibility: hidden;
  }
  &.boxed-layout {
    .main-menu > .first-dropdown::before {
      display: none;
    }
  }
}

.desktop-header-active .is-sticky .header .main-menu > .first-dropdown::before {
  transform: translateY(0);
}

/******************************
 Mobile Menu Desktop
*******************************/

.no-touchevents {
  &.mobile-menu-open {
    overflow: hidden;
  }
  .mobile-wrapper-open {
    overflow-y: scroll;
  }
}

.desktop-header-active {
  &.mobile-overlay{
    body{
      overflow: scroll;
    }
  }
  .menu-trigger {
    display: none !important;
    ul, li {
      height: 100%;
    }
    a {
      cursor: pointer !important;
      justify-content: flex-start;
      border-width: 0;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 5px 15px;
    }
  }
  &.mobile-menu-active {
    .menu-main-menu-wrapper {
      overflow-y: auto;
    }
    .site-wrapper #main-menu {
      display: none;
    }
    .menu-trigger {
      display: inline-flex !important;
      height: 100%;
    }
    .is-sticky {
      .menu-trigger {
        margin-right: auto;
      }
    }
  }
}





