@import 'variables.scss';

/******************************
 Product Grid
*******************************/

.product-grid:not(.swiper-wrapper){
  display: flex;
  flex-wrap: wrap;
}

.product-layout {
  display: flex;
  position: relative;
}

.product-thumb {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
  position: relative;
  transition: all 0.075s ease-out;
  overflow: hidden;
  .image {
    position: relative;
  }
  .product-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: auto;
      pointer-events: none;
      transition: all 0.075s ease-out;
      &.img-second {
        display: none;
      }
    }
  }
  &:hover {
    z-index: 2;
    .has-second-image {
      img {
        display: none;
        &.img-second {
          display: block;
        }
      }
    }
  }
  .caption {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    > *{
      //order: 50;
    }
  }
  .name {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.3;
    margin: 0;
    display: flex;
    align-items: center;
    max-width: 100%;
    //flex-grow: 1;
    a {
      display: block;
      //text-align: center;
    }
  }
  .stepper{
    height: auto;
  }
  .description {
    margin: 0 0 auto 0;
  }
  .rating {
    align-items: center;
    pointer-events: none;
    transition: all 0.1s ease-in-out;
  }
  .rating-stars {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;
  }
  .price-old {
    margin-right: 5px;
  }
  .price-tax {
    color: #999;
    display: block;
  }
  .countdown {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  }
  .stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 11px;
  }
}

//Quickview
.product-layout {
  .btn-quickview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    z-index: 1;
    transition: all 0.1s ease-in-out;
    span {
      display: none;
    }
  }
}

//Buttons
.product-layout {
  .button-group,
  .wish-group,
  .cart-group,
  .extra-group{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .btn-text {
    display: none;
  }
  .buttons-wrapper {
    margin-top: auto;
  }
}

.product-grid{
  .extra-group{
    justify-content: space-between;
  }
}

.phone .btn-quickview{
  display: none !important;
}

/******************************
Hovers
*******************************/

//.desktop .product-thumb{
//  width: calc(100% - 20px);
//  border-width: 5px;
//  transform: translate3d(0,0,0);
//  .button-group{
//    display: none;
//  }
//  &:hover{
//    z-index:100;
//    position: absolute;
//    border-color: $red;
//    .button-group{
//      display: flex;
//    }
//  }
//}

/******************************
Product List
*******************************/

.product-list {
  .product-thumb {
    flex-direction: row;
    flex-wrap: nowrap;
    .image {
      flex: 0 1 auto;
    }
    .caption {
      flex: 1 2 auto;
    }
    .caption {
      > * {
        justify-content: flex-start;
      }
      .price {
        margin-top: 0;
        text-align: left;
      }
    }
    .rating {
      margin: 0;
    }
    .name {
      margin: 0;
      padding-left: 0;
      padding-top: 0;
      flex-grow: 0;
      a {
        text-align: left;
      }
    }
    .description {
      display: flex;
      padding-top: 0;
      padding-left: 0;
      text-align: left;
      margin-bottom: 0;
      margin-top: 0;
    }
    .button-group {
      justify-content: flex-start;
    }
    .cart-group {
      justify-content: flex-start;
    }
    .caption {
      .buttons-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
      }
    }
  }
  .stats {
    > span {
      padding-right: 1em;
    }
  }
}


/******************************
 Side Products
*******************************/

.side-products{
  .product-layout{
    flex-direction: column;
  }
  .product-thumb{
    flex-direction: row;
    flex-wrap: wrap;
    .image{
      flex-grow: 1;
    }
    .product-img{
      height: 100%;
      width: 100%;
      img{
        width: auto;
      }
    }
    .caption{
      flex: 1000 0 80px;
      min-width: 80px;
    }
  }
  &:not(.swiper-wrapper){
    display: flex;
    flex-wrap: wrap;
  }
}

