@import '../variables.scss';


.block-header{
  img{
    transition: all 0.1s ease-in-out;
  }
  .icon{
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    transition: all 0.1s ease-in-out;
    &::before{
      transition: all 0.1s ease-in-out;
    }
  }
}

.block-body{
  height: 100%;
  transition: all 0.1s ease-in-out;
  display: flex;
  flex-direction:column;
  &::after{
    content: "";
    clear: both;
    display: table;
  }
}

.blocks-grid{
  .module-body{
    display: flex;
    flex-wrap: wrap;
  }
}

.block-content{
  //transition: all 0.1s ease-in-out; // prob block-expand
  > p:only-of-type{
    margin: 0;
  }
}

.block-footer{
  transition: all 0.1s ease-in-out;
  .btn{
    transition: all 0.1s ease-in-out;
    &::before{
      margin-right:5px;
    }
    &::after{
      margin-left:5px;
    }
  }
}

html[dir='rtl'] .block-footer{
  .btn{
    &::before{
      margin-right:0;
      margin-left:5px;
    }
    &::after{
      margin-left:0;
      margin-right:5px;
    }
  }
}

.block-map iframe {
  width: 100%;
  height: 100%;
}

.block-twitter {
  overflow-y: auto;
}


.module-blocks .nav-tabs > li > a::before{
  margin-right: 5px;
}

html[dir='rtl'] .module-blocks .nav-tabs > li > a::before{
  margin-right: 0;
  margin-left: 5px;
}
